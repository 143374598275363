import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService, SnackbarService } from '@services';
import { AppStore, UserStore } from '@stores';
import { CookieService } from 'ngx-cookie-service';
import { catchError, EMPTY, of, switchMap, tap } from 'rxjs';
import { InitializationService } from './initialization.service';
import { TokenData } from './models/api/token.interfaces';
import { TokenService } from './token.service';
import { UserService } from './user.service';

type Credentials = { username: string; password: string };

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private appStore = inject(AppStore);
  private cookieService = inject(CookieService);
  private initializationService = inject(InitializationService);
  private router = inject(Router);
  private snackbarService = inject(SnackbarService);
  private appStorageService = inject(AppStorageService);
  private tokenService = inject(TokenService);
  private userService = inject(UserService);
  private userStore = inject(UserStore);

  constructor() {
    this.appStorageService.getItem('user').then((user) => {
      this.userStore.updateUserData(user);
    });
  }

  loginUser(username: string, password: string, rememberMe = false) {
    this.appStorageService
      .getItem('userToken')
      .then((token) => {
        this.tokenService.revokeUserToken(token.accessToken).subscribe(() => {
          // TO CHANGE BACK!
          this.getUserData({ username, password }, rememberMe);
        });
      })
      .catch(() => {
        this.getUserData({ username, password }, rememberMe);
      });
  }

  storageLogin() {
    this.appStorageService
      .getItem('userToken')
      .then((token) => {
        this.getUserData(token);
      })
      .catch(() => {
        this.router.navigate(['login']);
      });
  }

  getUserData(login: Credentials | TokenData, rememberMe = false): void {
    const token = (login as TokenData).accessToken
      ? (login as TokenData)
      : null;
    (token
      ? of(token)
      : this.tokenService.getUserToken(
          (login as Credentials).username,
          (login as Credentials).password,
        )
    )
      .pipe(
        tap((token) => {
          this.tokenService.token = token;
          this.appStorageService.setItem('userToken', token);
        }),
        switchMap((userToken) =>
          this.userService.getUserAuthorizationData(
            token ? token.accessToken : userToken.accessToken,
          ),
        ),
        catchError(() => {
          this.snackbarService.showSnackBar({ message: 'Could not login' });
          this.appStore.setLoginState('loggedOut');
          this.router.navigate(['login']);
          return EMPTY;
        }),
      )
      .subscribe((user) => {
        /*         this.userStore.setRole(user.role); */
        this.userService.loadUser();
        if (rememberMe) {
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          this.cookieService.set(
            'username',
            this.userStore.email(),
            expirationDate,
          );
        }
      });
  }

  logoutUser() {
    this.userStore.clearUserData();
    this.appStorageService.removeItem('userToken');
    this.appStorageService.removeItem('user');
    this.appStore.setLoginState('loggedOut');
    this.router.navigate(['login']);
  }
}
