import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GroupPermission, Role } from '@api';
import { environment } from '@environment';
import { User } from '@models';
import { AppStorageService } from '@services';
import { UserStore } from '@stores';
import { EMPTY, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { getRole } from '../functions';
import { InitializationService } from './initialization.service';
import { UserDetails } from './models/api/user.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private initializationService = inject(InitializationService);
  private http = inject(HttpClient);
  private appStorageService = inject(AppStorageService);
  private userStore = inject(UserStore);

  getUserAuthorizationData(token: string): Observable<any> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('token', token);
    return this.http.post<any>(
      '/identity/connect/introspect',
      urlSearchParams.toString(),
      {
        headers: new HttpHeaders({
          Authorization:
            'Basic dmlydGFtZWRfY29ubmVjdF9yZXN0X2FwaTppbnRyb3NwZWN0X3Njb3BlX3NlY3JldF9GQzRGMzg3NS0xMDc5LTQ5NzUtQTYzRi0xQTdFMDAyNEY4NzA=',
        }),
      },
    );
  }

  loadUser(): void {
    this.http
      .get<UserDetails>('/api/users')
      .pipe(
        map((user) => {
          fetch(user.imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const userImageBlob = new Blob([blob], { type: 'image/png' });
              this.userStore.setImageBlob(userImageBlob);
            })
            .catch((error) =>
              console.error('Error fetching user image:', error),
            );
          return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            language: user.language,
          };
        }),
        catchError(() => {
          const user = this.appStorageService.getItem(
            'user',
          ) as unknown as User;
          if (user) {
            return of(user);
          }
          return EMPTY;
        }),
        tap((user) => {
          if (user) {
            this.userStore.updateUserData(user);
            this.initializationService.onlineInitalization();
          }
        }),
        switchMap((user) =>
          this.getUserRole(user.id, environment.adminGroupId),
        ),
        tap((role) => this.userStore.setRole(role as Role)),
      )
      .subscribe();
  }

  getUserRole(userId: number, groupId: number): Observable<string> {
    return this.http.get<any>(`/api/users/${userId}/permissions`).pipe(
      map(
        (permissions) =>
          permissions.groupPermissions.find(
            (permission: GroupPermission) => permission.groupId === groupId,
          )?.permissions,
      ),
      map((permissions) => (permissions ? getRole(permissions) : 'User')),
    );
  }
}
