import { Injectable, inject } from '@angular/core';
import { AppStorageService, NavigationService } from '@services';
import { AppStore, DataStore, ReportsStore, UserStore } from '@stores';
import { UsersStorageService } from './users-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  private appStore = inject(AppStore);
  private usersStorageService = inject(UsersStorageService);
  private dataStore = inject(DataStore);
  private navigationService = inject(NavigationService);
  private reportsStore = inject(ReportsStore);
  private appStorageService = inject(AppStorageService);
  private userStore = inject(UserStore);

  onlineInitalization() {
    this.userStore.loadLicences(null);
    this.appStore.setLoginState('loggedIn');
    this.navigationService.navigate();
  }

  offlineInitialization(email: string) {
    Promise.all([
      this.usersStorageService.getUser(email).then((user: any) => {
        delete user.encryptedPassword;
        delete user.imageBase64;
        this.userStore.updateUserData(user);
        this.userStore.setImageBlob(user.image);
      }),
      this.appStorageService.getItem('sceneSections').then((sceneSections) => {
        this.dataStore.setSceneSections(sceneSections);
      }),
      this.appStorageService.getItem('simulator').then((simulator) => {
        this.dataStore.setSimulator(simulator);
      }),
      this.appStorageService.getItem('reports').then((reports) => {
        this.reportsStore.setReports(reports);
      }),
    ]).then(() => {
      this.appStore.setLoginState('loggedIn');
      this.navigationService.navigate();
    });
  }
}
