import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { TokenData, RefreshTokenData } from './models/api/token.interfaces';

const HTTP_SCOPE =
  'openid resource_roles virtamed_connect_rest_api offline_access';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private http = inject(HttpClient);

  token: TokenData = {} as TokenData;

  revokeUserToken(token: string) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('token', token);
    return this.http.post(
      '/identity/connect/revocation',
      urlSearchParams.toString(),
      {
        headers: new HttpHeaders({
          Authorization:
            'Basic VmlydGFNZWQuQ29ubmVjdC5SZXN0QVBJOnNlY3JldF80NzFGRjk0My0zMUUyLTQwM0QtOUQ4Qy02QjNEN0JCNDU1M0I=',
        }),
      },
    );
  }

  getUserToken(username: string, password: string): Observable<TokenData> {
    return this.http
      .post<TokenData>('/api/users/identity/token', {
        UserName: username,
        Password: password,
        Scope: HTTP_SCOPE,
        Client: 'FrontEnd',
      })
      .pipe(
        tap((token) => {
          this.token = token;
        }),
        catchError((error) => {
          throw error;
        }),
      );
  }

  acquireAccessToken(token = ''): Observable<RefreshTokenData> {
    const formData = new URLSearchParams();
    formData.append('refresh_token', token);
    formData.append('client_id', 'VirtaMed.Connect.RestAPI');
    formData.append(
      'client_secret',
      'secret_471FF943-31E2-403D-9D8C-6B3D7BB4553B',
    );
    formData.append('grant_type', 'refresh_token');

    return this.http.post<RefreshTokenData>(
      '/api/users/identity/token',
      formData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      },
    );
  }
}
